import React from "react";

const ContactCTA = () => {
  return (
    <div className="bg-white">
    <div className="bg-gradient-to-r from-teal-500 to-cyan-600">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-24 lg:px-8 lg:flex lg:items-center lg:justify-between">
        <h2 className="text-3xl font-extrabold tracking-tight text-white md:text-4xl">
          <span className="block">Book a meeting?</span>
          <span className="block text-cyan-900">Feel free to have a look in our calendar.</span>
        </h2>
        <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
          <div className="inline-flex rounded-md shadow">
            <a href="https://meetings.hubspot.com/johan106" target="_blank" rel="noopener noreferrer" className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md bg-cyan-50 text-cyan-700 hover:bg-cyan-100 hover:text-cyan-900">
              Let's meet
            </a>
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}

export default ContactCTA