import React from "react";

const ContactForm = () => {
  return (
    <div className="bg-white py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24">
      <div className="relative max-w-xl mx-auto">
        <svg className="absolute left-full transform translate-x-1/2" width="404" height="404" fill="none" viewBox="0 0 404 404" aria-hidden="true">
          <defs>
            <pattern id="85737c0e-0916-41d7-917f-596dc7edfa27" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="404" fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
        </svg>
        <svg className="absolute right-full bottom-0 transform -translate-x-1/2" width="404" height="404" fill="none" viewBox="0 0 404 404" aria-hidden="true">
          <defs>
            <pattern id="85737c0e-0916-41d7-917f-596dc7edfa27" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="404" fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
        </svg>
        <div className="text-center">
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Get in touch with us
          </h2>
          <p className="mt-4 text-lg leading-6 text-gray-500">
            How can we help? We provide the service we'd want to experience ourselves!
          </p>
        </div>
        <div className="mt-12">
          <form className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8" name="dumblefy-com-contact" method="post" data-netlify="true" netlify-honeypot="bot-field">
            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
            <input type="hidden" name="form-name" value="dumblefy-com-contact" />
            <p className="hidden">
              <label>Don’t fill this out if you’re human: <input name="bot-field" /></label>
            </p>
            <div>
              <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">First name</label>
              <div className="mt-1">
                <input type="text" name="first_name" id="first_name" autoComplete="given-name" className="py-3 px-4 block w-full shadow-sm focus:ring-cyan-500 focus:border-cyan-500 border-gray-300 rounded-md" />
              </div>
            </div>
            <div>
              <label htmlFor="last_name" className="block text-sm font-medium text-gray-700">Last name</label>
              <div className="mt-1">
                <input type="text" name="last_name" id="last_name" autoComplete="family-name" className="py-3 px-4 block w-full shadow-sm focus:ring-cyan-500 focus:border-cyan-500 border-gray-300 rounded-md" />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="company" className="block text-sm font-medium text-gray-700">Company</label>
              <div className="mt-1">
                <input type="text" name="company" id="company" autoComplete="organization" className="py-3 px-4 block w-full shadow-sm focus:ring-cyan-500 focus:border-cyan-500 border-gray-300 rounded-md" />
              </div>
            </div>
            <div className="sm:col-span-2">
              <div className="flex justify-between">
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                <span id="email_description" className="text-sm text-gray-500">Required</span>
              </div>
              <div className="mt-1">
                <input id="email" name="email" type="email" autoComplete="email" required className="py-3 px-4 block w-full shadow-sm focus:ring-cyan-500 focus:border-cyan-500 border-gray-300 rounded-md" />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="phone_number" className="block text-sm font-medium text-gray-700">Phone Number</label>
              <div className="mt-1">
                <input type="text" name="phone_number" id="phone_number" autoComplete="tel" className="py-3 px-4 block w-full focus:ring-cyan-500 focus:border-cyan-500 border-gray-300 rounded-md" />
              </div>
            </div>
            <div className="sm:col-span-2">
              <div className="flex justify-between">
                <label htmlFor="message" className="block text-sm font-medium text-gray-700">Message</label>
                <span id="message_description" className="text-sm text-gray-500">Required</span>
              </div>
              <div className="mt-1">
                <textarea id="message" name="message" rows="4" required className="py-3 px-4 block w-full shadow-sm focus:ring-cyan-500 focus:border-cyan-500 border-gray-300 rounded-md" />
              </div>
            </div>
            <div className="sm:col-span-2">
              <button type="submit" className="block w-full py-3 px-4 rounded-md shadow bg-gradient-to-r from-teal-500 to-cyan-600 text-white font-medium hover:from-teal-600 hover:to-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-400 focus:ring-offset-gray-900">
                Let's talk
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ContactForm