import React from "react";
import SEO from "../components/SEO";
import Layout from "../components/Layout";
import ContactForm from "../components/ContactForm";
import ContactCTA from "../components/ContactCTA";
import ContactTwoColumn from "../components/ContactTwoColumn";

const ContactPage = () => (
  <>
    <SEO
      title={"Get in touch with us"}
      description={"How can we help? We provide the service we'd want to experience ourselves!"}
    />
    <Layout>
      <ContactForm />
      <ContactTwoColumn />
      <ContactCTA />
    </Layout>
  </>
)

export default ContactPage